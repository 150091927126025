import { IAuthRepositoryCB } from "modules/auth/application/interfaces/repository/IAuthRepository";
import { IAuthResponse } from "modules/auth/models/dto/IAuthResponse";

export const AuthRepository: IAuthRepositoryCB = () => {
  const key = "@auth";
  const set = (auth: IAuthResponse) => {
    const { locals, ...authWithoutLocals } = auth;
    localStorage.setItem(key, JSON.stringify(authWithoutLocals));
  };
  const get = (): IAuthResponse | null => {
    try {
      const response = JSON.parse(localStorage.getItem(key) ?? "") as IAuthResponse;
      return response;
    } catch {
      return null
    }
  };
  const remove = () => {
    localStorage.removeItem(key);
  };

  return { set, get, remove };
};
