import { AxiosError } from "axios";
import { IApi } from "models/api/IApi";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Api } from "services/api/Api";
import { deviceConfigApi } from "services/api/equipamento/DeviceConfigApi";
import { useUi } from "../userInterface/UserInterfaceContext";
import { RemoveAuthUseCase } from "modules/auth/application/useCases/RemoveAuthUseCase";
import { AuthRepository } from "services/repository/auth/AuthRepository";
import { useHistory } from "react-router-dom";

interface IApiContext {
  setOnError: (error: any) => void;
}

const ApiContext = createContext<IApiContext>({} as IApiContext);

export const useApi = () => {
  const context = useContext(ApiContext);
  return context;
};

export const ApiProvider: FC = ({ children }) => {
  const { toast } = useUi();
  const disableError = useRef(false);
  const { replace } = useHistory();

  const onErrorApi = useCallback(
    (
      error: AxiosError<{
        message?: string;
        error?: string;
        Message?: string,
        errors?: { messages: string[] }[]
        messages?: string[];
      }>,
    ) => {
      if (!disableError.current) {
        if (error?.response?.status === 401) {
          RemoveAuthUseCase(AuthRepository);
          replace("/login");
          return;
        }

        let message = "Falha ao conectar ao servidor";
        if (error?.response?.status === 401) {
          message = "Não Autorizado";
        } else if (error?.response?.status === 500) {
          message = "Falha ao conectar ao servidor";
        } else if (error.response?.data) {
          message = error.response?.data?.error ||
            error.response?.data?.message ||
            error.response?.data?.Message ||
            (error.response?.data?.messages?.length ? error.response?.data?.messages?.map((error) => error).join('\n') : "") ||
            (typeof (error.response?.data as any) === 'string' ? (error.response?.data as string) : "") ||
            `${error.response.status} - Erro ao concluir esta requisição`;

          const complement = !!error.response?.data?.errors?.length ? error.response?.data?.errors?.flatMap(error => error?.messages).map((error) => error).join('\n') : "";
          message = `${message} ${complement}`;
        } else {
          message = "Erro ao concluir esta requisição";
        }

        toast(message, "error");
      }
    },
    [replace, toast]
  );

  const configureInterceptors = useCallback(
    (api: IApi) => {
      api.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
          if (!error.response?.config?.params?.disableError) {
            onErrorApi(error);
          }
          throw error;
        }
      );
      api.interceptors.request.use((value) => {
        if (value?.params?.disableError) {
          disableError.current = true;
        } else {
          disableError.current = false;
        }
        return value;
      });
    },
    [onErrorApi]
  );

  const setOnError = useCallback((onError: (error: any) => void) => {
    //TODO: Coisa do thiago
  }, []);

  useEffect(() => {
    const api = Api();
    configureInterceptors(api);
    configureInterceptors(deviceConfigApi);
  }, [configureInterceptors]);

  return (
    <ApiContext.Provider value={{ setOnError }}>
      <>{children}</>
    </ApiContext.Provider>
  );
};
