import { IApi } from "models/api/IApi";
import axios, { AxiosRequestConfig } from "axios";
import { QueryClient } from "react-query";
import { AuthRepository } from "services/repository/auth/AuthRepository";
import { UseLocalStore } from "modules/local/presentation/store/UseLocalStore";

// import { apiUrl } from "Enviroment";

export type Response<BodyType> = {
  body: BodyType;
  status?: number;
  error?: Error | string;
};

export type BaseErrorTypes = Response<any>;

export type RequestType = Omit<AxiosRequestConfig, "data"> & {
  body?: AxiosRequestConfig["data"];
};


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});


const getAuthorizationHeader = () => {
  const token = AuthRepository().get()?.access_token;
  return `Bearer ${token}`;
};

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Authorization': getAuthorizationHeader(),
    'X-Owner-Id': UseLocalStore.getState().currentLocal?.id,
  },
};

const apiMovementsConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_DASHBOARD_URL,
};

export const api = axios.create(config);
export const apiDashGeneral = axios.create(apiMovementsConfig);

apiDashGeneral.interceptors.request.use((request) => {
  request.headers['Authorization'] = getAuthorizationHeader();
  request.headers['X-Owner-Id'] = UseLocalStore.getState().currentLocal?.id;
  return request;
});

export const Api = (): IApi => {
  return api;
};

export const request = async <BodyType,>({
  body: data,
  ...config
}: RequestType): Promise<Response<BodyType>> => {
  try {
    const response = await api({ ...config, data });

    return { body: response?.data, status: response?.status };
  } catch (error: any) {
    return {
      body: error?.response?.data,
      status: error?.response?.status,
      error: error?.response,
    };
  }
};

export const normalizeResponse = <ResponseBodyType,>(
  response: Response<ResponseBodyType>
) => {
  if (response.error) throw response.error;

  return response.body;
};


