import { IOwnerLocationResponse } from "modules/ownerLocations/domain/dto/IOwnerLocationResponse";
import { api } from "../Api";
import { IOwnerLocation } from "modules/ownerLocations/domain/interfaces/IOwnerLocation";

export const getOwnerLocations = async (): Promise<IOwnerLocation[]> => {
  const response = await api.get<IOwnerLocationResponse[]>("/Proprietario/BuscarLocaisOndeUsuarioEProprietario");

  return response.data.map((location) => ({
    id: location.localId,
    name: location.nomeLocal,
    status: location.status,
    isEvent: location.evento,
  }));
};
