import { useCallback, useEffect, useState } from "react";
import { api } from "services/api/Api";
import { AuthRepository } from "services/repository/auth/AuthRepository";
import PageRouters from "routers/PageRouters";
import { IAuthResponse } from "../../models/dto/IAuthResponse";
import { SaveAuthUseCase } from "../../application/useCases/SaveAuthLocalUseCase";
import { RemoveAuthUseCase } from "../../application/useCases/RemoveAuthUseCase";
import { IAuthContext } from "../context/IAuthAdminContext";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import RemoveSelectedLocalsUseCase from "modules/dashboard/application/useCases/RemoveSelectedLocalsUseCase";
import { DashboardSelectedLocals } from "services/repository/dashboard/DashboardSelectedLocals";
import RemoveRemovedChartsUseCase from "modules/salesDashboard/application/useCases/removedCharts/RemoveRemovedChartsUseCase";
import { DashboardRemovedCharts } from "services/repository/dashboard/DashboardRemovedCharts";
import { deviceConfigApi } from "services/api/equipamento/DeviceConfigApi";
import CargosApi from "services/api/cargos/CargosApi";
import { getRolesByFunctionUseCase } from "modules/cargos/application/useCases/getRolesByFunctionUseCase";
import { UseLocalStore } from "modules/local/presentation/store/UseLocalStore";
import { FetchOwnerLocationUseCase } from "modules/ownerLocations/application/useCases/FetchOwnerLocationUseCase";
import { IOwnerLocation } from "modules/ownerLocations/domain/interfaces/IOwnerLocation";

export const UseAuthContext = (disableRedirectToLogin?: boolean): IAuthContext => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [auth, setAuth] = useState<IAuthResponse>();
  const [ownerLocals, setOwnerLocals] = useState<IOwnerLocation[]>([]);

  const [currentLocal, setLocals] = UseLocalStore(state => [state.currentLocal, state.setLocals]);
  const fetchLocals = async () => {
    const fetchedLocais = await FetchOwnerLocationUseCase();
    setOwnerLocals(fetchedLocais);
  };
  const [functionRoles, setFunctionRoles] = useState<{ name: string, id: string }[]>([]);
  // const { setOnError } = useApi();
  const { replace } = useHistory();
  const { path } = useRouteMatch();
  const {  hideLoading } = useUi();

  const updateToken = useCallback((access_token: string) => {
    api.defaults.headers.Authorization = `Bearer ${access_token}`;
    deviceConfigApi.defaults.headers.Authorization = `Bearer ${access_token}`;
    setIsAuth(true);
  }, []);

  const logout = useCallback(() => {
    RemoveAuthUseCase(AuthRepository);
    RemoveSelectedLocalsUseCase(DashboardSelectedLocals);
    RemoveRemovedChartsUseCase(DashboardRemovedCharts);
    localStorage.removeItem("showOldPortalLayout");
    localStorage.removeItem("@showOnboarding");
    setIsAuth(false);
    localStorage.clear();
    path !== "/login" && path !== "/signin" && path !== "/" &&
      localStorage.setItem("@lastPage", path);
    !disableRedirectToLogin && replace(PageRouters.login); //TODO: Redirecionar para a Tela de  LOGIN
  }, [disableRedirectToLogin, path, replace]);

  const saveAuth = useCallback(
    (auth: IAuthResponse) => {
      setAuth(auth);
      updateToken(auth.access_token);
      fetchLocals();
      SaveAuthUseCase(auth, AuthRepository);
    },
    [updateToken]
  );





  useEffect(() => {
    const authLocalStorage = AuthRepository();
    const auth = authLocalStorage.get();
    if (auth) {
      updateToken(auth?.access_token);
      setAuth(auth);
      hideLoading();
    } else {
      logout();
    }
  }, [hideLoading, logout, path, setLocals, updateToken]);

  const getFuctionRoles = useCallback(async (_currentLocalId: string) => {
    const service = CargosApi();
    const response = await getRolesByFunctionUseCase(service, _currentLocalId);
    setFunctionRoles(response);
  }, []);

  useEffect(() => {
    if (currentLocal && auth) {
      getFuctionRoles(currentLocal.id ?? "");
    }
  }, [auth, currentLocal, getFuctionRoles])

  const hasAccessRole = useCallback(
    (role?: string) => {
      if (role && role !== "") {
        if (auth) {
          const _functionRoles = functionRoles.some((item) => item.name === role);
          const _roles = auth.roles?.some((item) => item === role);
          const hasAccess = _functionRoles || _roles;
          return hasAccess;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    [auth, functionRoles]
  );
  return {
    saveAuth,
    isAuth,
    logout,
    auth,
    hasAccessRole,
    ownerLocals,
  };
};
